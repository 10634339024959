import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';
import {
  types,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersError,
  openCloseUsersSuccess,
  openCloseUsersError,
  saveUserSuccess,
  saveUserError,
  closeDialogEditUser,
} from 'generic/core/users/actions';
import {
  openCloseUsers,
  fetchUsers as fetchUsersApi,
  createUser,
  patchUser,
} from 'generic/api/users';
import { snackActions } from 'generic/utils/snackbar';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { OPEN_USER_STATUS, CLOSED_USER_STATUS } = QES_CONSTANTS;

function* workFetchUsers({ params }) {
  try {
    const users = yield call(fetchUsersApi, { uriParams: params });
    yield put(fetchUsersSuccess(users));
  } catch (error) {
    yield put(fetchUsersError(error));
    console.error(error);
    snackActions.error(i18next.t('users.error_fetching_users'));
  }
}

function* watchFetchUsers() {
  yield takeLatest(types.FETCH_USERS, workFetchUsers);
}

function* workOpenCloseUsers({ ids, open, status }) {
  try {
    const etat = (open) ? OPEN_USER_STATUS : CLOSED_USER_STATUS;
    const message = (open) ? 'users.users_opened' : 'users.users_closed';
    const params = {
      utilisateurs: ids,
      etat_utilisateur: etat,
    };

    const results = yield call(openCloseUsers, { bodyItems: { ...params } });
    yield put(openCloseUsersSuccess(results));
    snackActions.success(i18next.t(message, { count: ids.length }));
    yield put(fetchUsers({ etat: status }));
  } catch (error) {
    yield put(openCloseUsersError(error));
    console.error(error);
    snackActions.error(i18next.t('users.error_updating_users'));
  }
}

function* watchOpenCloseUsers() {
  yield takeLatest(types.OPEN_CLOSE_USERS, workOpenCloseUsers);
}

function* workSaveUser({ params }) {
  try {
    let endpoint = createUser;
    if (params.utilisateur !== null) {
      endpoint = patchUser;
    }
    const results = yield call(endpoint, { bodyItems: { ...params } });
    yield put(saveUserSuccess(results));
    yield put(closeDialogEditUser());
    // On refresh la liste des utilisateurs
    yield put(fetchUsers({ etat: params.etat_utilisateur }));
    snackActions.success(i18next.t('profile.user_saved'));
  } catch (error) {
    yield put(saveUserError(error));
    console.error(error);
    snackActions.error(i18next.t('profile.user_saved_error'));
  }
}

function* watchSaveUser() {
  yield takeLatest(types.SAVE_USER, workSaveUser);
}

export default {
  watchFetchUsers,
  watchOpenCloseUsers,
  watchSaveUser,
};
