import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { snackActions } from 'generic/utils/snackbar';
import { treeFlattener } from 'generic/utils/utils';
import {
  types,
  fetchAnRFolders,
  saveAnRFavoriteStatus,
  setEditNewFolder,
  cleanupAnRFolders,
} from 'generic/core/anr/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import CenteredCircularProgress from 'generic/components/ui/CenteredCircularProgress';
import Folders from 'generic/components/pages/anr/Folders';

const FoldersContainer = () => {
  const loading = useSelector((state) => state.anr.foldersLoading);
  const folders = useSelector((state) => state.anr.folders);
  const rawFolders = useSelector((state) => state.anr.rawFolders);
  const hasGlimpse = useSelector((state) => state.anr.user.rights.glimpse);
  const maxFolders = useSelector((state) => state.anr.user.max_profiles);
  const canCreateFolders = useSelector((state) => state.anr.user.rights.setter);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchFolders, setSearchFodlers] = useState('');

  const {
    id,
    view,
  } = useParams();

  useEffect(() => {
    // A la création du reducer, folders vaut null,
    // on tente de les charger une première fois ici
    if (folders === null && !loading) {
      dispatch(fetchAnRFolders());
    }
  }, [folders, dispatch, loading]);

  useEffect(() => (
    // Cleanup d'anr (dossiers et articles) lorsque le composant est "unmount"
    // (donc quand on change de page)
    () => dispatch(cleanupAnRFolders())
  ), [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(folders) && _.isEmpty(id) && _.isEmpty(view)) {
      if (hasGlimpse) {
        history.replace('/anr/folders/0/glimpse');
        return;
      }
      const flattenedFolders = treeFlattener(_.flatten(folders));
      const folder = _.find(flattenedFolders, { is_root: false });
      if (!_.isEmpty(folder)) {
        const urlView = folder.has_news || (!folder.has_news && !folder.has_quotes) ? 'news' : 'quotes';
        history.push(`/anr/folders/${folder.id}/${urlView}`);
      }
    }
  }, [hasGlimpse, folders, history, id, view]);

  const handleToggleFavoriteStatus = (itemId, status) => {
    const foldersIds = itemId.length > 24 ? itemId.match(/.{1,24}/g) : [itemId];

    dispatch(saveAnRFavoriteStatus({ folders_ids: foldersIds, status }));
  };

  const handleOpenDeleteConfirmDialog = (item) => {
    dispatch(setConfirmDialogData({
      title: t('anr.delete_folder'),
      message: `${t('anr.delete_folder_sure')} "${item.title}" ?`,
      submitColor: 'error',
      waitForActions: [types.DELETE_FOLDER_SUCCESS, types.DELETE_FOLDER_ERROR],
      action: {
        type: types.DELETE_FOLDER,
        params: {
          folder_id: item.id,
        },
      },
    }));
  };

  const handleEditNewFolder = (event, item) => {
    const flattenedFolders = treeFlattener(_.flatten(folders));
    const nbFolders = _.filter(flattenedFolders, { is_root: false }).length;
    if (nbFolders === maxFolders) {
      event.preventDefault();
      event.stopPropagation();
      snackActions.warning(t('anr.too_much_folders'));
    } else if (item !== null) {
      dispatch(setEditNewFolder(item));
    }
  };

  const handleSearchFolders = (event) => {
    setSearchFodlers(event.target.value);
  };

  if (_.isEmpty(folders)) {
    if (loading) {
      return <CenteredCircularProgress />;
    }
    return null;
  }

  const numberFolders = _.map(
    treeFlattener(rawFolders).filter(
      (item) => !item.is_root,
    ),
    'title',
  ).length;

  let searchedFolders = folders;
  let canCollapseRoot = true;
  let noResults = false;

  /* Recherche des dossiers et des branches */
  if (searchFolders.length >= 2) {
    canCollapseRoot = false;
    // Remplacement des "/" par "/ " ou " /" si nécessaire
    const syntaxSearchFolders = searchFolders.replace(/\s?\/\s?/g, (match) => {
      if (match.trim() === '/') {
        return ' / ';
      }
      return match;
    });

    const filterFoldersIds = _.map(
      treeFlattener(rawFolders).filter(
        (folder) => folder.title.toLowerCase().includes(syntaxSearchFolders.toLowerCase()),
      ),
      'id',
    );

    // Filtrer les dossier en fonction de la recherche.
    const filterTree = (tree, filter) => tree.map((node) => {
      if (node.children) {
        return ({
          ...node,
          children: filterTree(node.children, filter),
        });
      }
      return ({
        ...node,
      });
    }).filter((node) => {
      let foundInFilterFoldersIds = false;
      // eslint-disable-next-line consistent-return
      _.forEach(filterFoldersIds, (filterFolderId) => {
        if (node.id.indexOf(filterFolderId) !== -1) {
          foundInFilterFoldersIds = true;
          return false;
        }
      });
      return foundInFilterFoldersIds;
    });
    searchedFolders = _.map(folders, (folder) => filterTree(folder, syntaxSearchFolders));
    noResults = _.isEmpty(filterFoldersIds);
  }

  let activeItemId = id;
  if (activeItemId === '0') {
    activeItemId = view;
  }

  return (
    <Folders
      folders={searchedFolders}
      hasGlimpse={hasGlimpse}
      activeItemId={activeItemId}
      canCreateFolders={canCreateFolders}
      canCollapseRoot={canCollapseRoot}
      noResults={noResults}
      numberFolders={numberFolders}
      searchFolders={searchFolders}
      handleToggleFavoriteStatus={handleToggleFavoriteStatus}
      handleEditNewFolder={handleEditNewFolder}
      handleOpenDeleteConfirmDialog={handleOpenDeleteConfirmDialog}
      handleSearchFolders={handleSearchFolders}
    />
  );
};

export default React.memo(FoldersContainer);
