import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadFilesDialog from 'generic/components/dialogs/UploadFilesDialog';
import { closeDialogUploadFiles } from 'generic/core/actions/actions';
import { uploadFileToCreateDocument } from 'generic/core/files/actions';
import _ from 'lodash';
import PropTypes from 'prop-types';

const UploadFilesDialogContainer = ({
  baseId,
  baseMisePageId,
}) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.actions.dialogUploadFilesOpened);
  const filesToUpload = useSelector((state) => state.files.filesToUpload);

  const uploadParams = {
    base: baseId,
    base_mise_page: baseMisePageId,
    type: 10,
  };

  const handleClose = () => {
    dispatch(closeDialogUploadFiles());
  };

  const handleUploadClick = () => {
    if (_.isEmpty(filesToUpload)) {
      return;
    }
    _.forEach(filesToUpload, (value) => {
      if (!value.success) {
        const fd = new FormData();
        fd.append('file', value.file);
        dispatch(uploadFileToCreateDocument(uploadParams, fd, value.name));
      }
    });
  };

  if (!open) {
    return null;
  }
  return (
    <UploadFilesDialog
      filesToUpload={filesToUpload}
      handleClose={handleClose}
      handleUploadClick={handleUploadClick}
    />
  );
};

UploadFilesDialogContainer.propTypes = {
  baseId: PropTypes.number.isRequired,
  baseMisePageId: PropTypes.number.isRequired,
};

export default UploadFilesDialogContainer;
