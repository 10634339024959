import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import UploadFilesToCreateDocumentsContainer from 'generic/containers/UploadFilesToCreateDocumentsContainer';

const UploadFilesToCreateDocumentsDialog = ({
  handleClose,
  baseId,
  baseMisePageId,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      scroll="paper"
      fullWidth
      open
    >
      <DialogTitle>
        {t('form.upload')}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <UploadFilesToCreateDocumentsContainer
          baseId={baseId}
          baseMisePageId={baseMisePageId}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleClose}
        >
          {t('ui.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadFilesToCreateDocumentsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  baseId: PropTypes.number.isRequired,
  baseMisePageId: PropTypes.number.isRequired,
};

export default UploadFilesToCreateDocumentsDialog;
