import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'generic/utils/dateUtils';

import { cartOrNewsletterDocumentPropType } from 'generic/core/qes/proptypes';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const DocumentForNewsletter = ({
  panierDocument,
  handleGetDocumentComplete,
}) => {
  const { t } = useTranslation();

  const document = panierDocument.qesdocument;
  const title = _.get(document, 'TITLE', [`[${t('document.title_undefined')}]`]);

  let url = document.URL;
  if (document.QUOTE_URL) {
    url = document.QUOTE_URL;
  }

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: url,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }

  return (
    <Box display="block" alignItems="center">
      <Typography
        component="span"
        display="block"
      >
        <Box
          {...titleLinkProps}
          color="text.primary"
          sx={{ cursor: 'pointer' }}
        >
          {title}
        </Box>

        <Box
          color="text.neutral"
        >
          {panierDocument.texte}
        </Box>
      </Typography>

      <Box
        display="flex"
        flexWrap="wrap"
        flexGrow="1"
        lineHeight="1rem"
        sx={{ wordBreak: 'break-word' }}
      >
        {document.PUB_DATE && (
          <Fragment>
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              fontSize="0.8rem"
            >
              {format(document.PUB_DATE)}
            </Typography>
            <Box mx={1} py="3px">
              <Divider
                orientation="vertical"
              />
            </Box>
          </Fragment>
        )}
        {document.SOURCE && (
          <Typography
            component="span"
            variant="body2"
            color="text.neutral"
            fontSize="0.8rem"
          >
            {_.isArray(document.SOURCE) ? document.SOURCE[0] : document.SOURCE}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

DocumentForNewsletter.propTypes = {
  panierDocument: cartOrNewsletterDocumentPropType.isRequired,
  handleGetDocumentComplete: PropTypes.func,
};

DocumentForNewsletter.defaultProps = {
  handleGetDocumentComplete: null,
};

export default DocumentForNewsletter;
