import React from 'react';
import { useSelector } from 'react-redux';
import { overrideRessource } from 'generic/utils/utils';
import { some } from 'lodash';

const Header = overrideRessource('components/ui/Header');

const HeaderContainer = () => {
  const logged = useSelector((state) => state.auth.logged);
  const user = useSelector((state) => state.config.user);

  const hasCarts = useSelector((state) => some(state.config.bases, { panier: true }));
  const hasNewsletters = useSelector((state) => some(state.config.bases, { newsletter: true }));
  const hasWatches = useSelector((state) => some(state.config.bases, { veille: true }));

  if (!logged) {
    return '';
  }

  return (
    <Header
      user={user}
      hasWatches={hasWatches}
      hasCarts={hasCarts}
      hasNewsletters={hasNewsletters}
    />
  );
};

export default React.memo(HeaderContainer);
