import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Drawer,
  Skeleton,
  Typography,
} from '@mui/material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { Close } from '@mui/icons-material';
import CopyToClipboard from 'generic/components/ui/CopyToClipboard';

const Summarize = ({
  handleCloseSummarize,
  building,
  hasDrawer,
  summary,
}) => {
  const { t } = useTranslation();

  const content = (
    <Box m={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">
          {t('summarize.summary')}
        </Typography>
        {hasDrawer && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <TooltipButton
              tag="iconButton"
              onClick={handleCloseSummarize}
              size="small"
              edge="end"
              title={t('ui.close')}
            >
              <Close fontSize="small" />
            </TooltipButton>
          </Box>
        )}
      </Box>
      <Box mt={3}>
        {building && (
          <Skeleton variant="rectangular" height="calc(100vh - 104px)" />
        )}
        {!building && (
          <Box
            overflow="auto"
            height="calc(100vh - 104px)"
            position="relative"
            bgcolor="#f6f8fa"
            borderRadius={2}
            p={4}
          >
            <Box position="absolute" top={10} right={10}>
              <CopyToClipboard textToCopy={summary} />
            </Box>
            {summary && (
              summary
            )}
            {!summary && (
              <Alert severity="info">{t('summarize.no_summary')}</Alert>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );

  return hasDrawer ? (
    <Drawer
      anchor="right"
      variant="temporary"
      open
      onClose={handleCloseSummarize}
      PaperProps={{ sx: { width: { xs: '80%', md: '60%', lg: '40%' } } }}
    >
      {content}
    </Drawer>
  ) : (
    content
  );
};

Summarize.propTypes = {
  summary: PropTypes.string,
  handleCloseSummarize: PropTypes.func.isRequired,
  building: PropTypes.bool,
  hasDrawer: PropTypes.bool,
};

Summarize.defaultProps = {
  summary: '',
  building: false,
  hasDrawer: false,
};

export default Summarize;
