import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, Button, Drawer } from '@mui/material';
import ResultsContainer from 'generic/containers/ResultsContainer';
import { prettyPrintNumber } from 'generic/utils/mathUtils';
import { ChevronLeft } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  root: {
    '& .drawerButton': {
      borderRadius: '15px 0 0 15px',
      lineHeight: '24px',
      padding: '3px 10px 3px 3px',
      borderRight: 'none',
    },
  },
}));

const DashboardResults = React.memo(({ nbResults }) => {
  const [resultsSidenavOpened, setResultsSidenavOpened] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Button
        variant="contained"
        className="drawerButton"
        onClick={() => setResultsSidenavOpened(true)}
      >
        <ChevronLeft sx={{ mr: '8px' }} />
        {`${prettyPrintNumber(nbResults)} ${t('results.document', { count: nbResults })}`}
      </Button>
      <Drawer
        anchor="right"
        open={resultsSidenavOpened}
        ModalProps={{
          keepMounted: true,
        }}
        onClose={() => setResultsSidenavOpened(false)}
        PaperProps={{ sx: { width: { xs: '85%', md: '80%', lg: '70%' } } }}
      >
        <ResultsContainer />
      </Drawer>
    </Box>
  );
});

DashboardResults.propTypes = {
  nbResults: PropTypes.number,
};

DashboardResults.defaultProps = {
  nbResults: 0,
};

export default DashboardResults;
