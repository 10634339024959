import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UploadFiles from 'generic/components/pages/UploadFiles';
import { cleanFilesToUpload, addFileToUpload, removeFileToUpload } from 'generic/core/files/actions';
import QES_CONSTANTS from 'generic/core/qes/constants';
import PropTypes from 'prop-types';

const {
  FILE_UPLOAD_FORMATS,
} = QES_CONSTANTS;

const UploadFilesContainer = ({
  filesToUpload,
}) => {
  const dispatch = useDispatch();

  // Cleanup des fichiers lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanFilesToUpload())
  ), [dispatch]);

  const handleFileChange = (target) => {
    if (target.files && target.files.length > 0) {
      dispatch(addFileToUpload([...target.files]));
    }
  };

  const handleRemoveFile = (file) => {
    dispatch(removeFileToUpload(file));
  };

  return (
    <UploadFiles
      acceptExtensions={FILE_UPLOAD_FORMATS.docToUpload.fileExtensions}
      handleFileChange={handleFileChange}
      handleRemoveFile={handleRemoveFile}
      filesToUpload={filesToUpload}
    />
  );
};

UploadFilesContainer.propTypes = {
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
};

UploadFilesContainer.defaultProps = {
  filesToUpload: [],
};

export default UploadFilesContainer;
