export default {
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: false,

  override_all: {
    DASHBOARDS_PARAMS_PER_BASE: {
      default: {
        COOCCURRENCES_FIELDS: [
          { value: 'QES_StixThreatActor.verbatim', name: 'Threat Actors', active: true },
          { value: 'QES_StixAttackPattern.verbatim', name: 'Attack Patterns', active: true },
          { value: 'QES_StixMalware.verbatim', name: 'Malwares', active: true },
          { value: 'QES_StixTool.verbatim', name: 'Tools', active: true },
          { value: 'QES_Ipv4.verbatim', name: 'Ipv4', active: true },
          { value: 'QES_Person.verbatim', name: 'Person' },
          { value: 'QES_Company.verbatim', name: 'Company' },
          { value: 'QES_Organization.verbatim', name: 'Organization' },
          { value: 'QES_ConceptRisk.verbatim', name: 'ConceptRisk' },
        ],

        RELATIONS_NODES_FIELDS: [
          { value: 'Organonoff', name: 'Organonoff', active: true },
          { value: 'Organization', name: 'Organization', active: true },
          { value: 'Product', name: 'Product', active: true },
          { value: 'Person', name: 'Person', active: true },
          { value: 'Concept', name: 'Concept', active: true },
          { value: 'Coldconcept', name: 'Coldconcept', active: true },
          { value: 'Location', name: 'Location', active: true },
          { value: 'Company', name: 'Company', active: true },
          { value: 'Phone', name: 'Phone', active: true },
          { value: 'Event', name: 'Event', active: true },
          { value: 'Unlocalized', name: 'Unlocalized', active: true },
          { value: 'Media', name: 'Media', active: true },
          { value: 'Email', name: 'Email', active: true },
          { value: 'Address', name: 'Address', active: true },
          { value: 'JobTitle', name: 'Job Title', active: true },
        ],

        RELATIONS_LINKS_FIELDS: [
          { name: 'Aim', value: 'Aim', active: true },
          { name: 'Alert', value: 'Alert', active: true },
          { name: 'Appoint', value: 'Appoint', active: true },
          { name: 'Attack', value: 'Attack', active: true },
          { name: 'Develop', value: 'Develop', active: true },
          { name: 'Help', value: 'Help', active: true },
          { name: 'Identification', value: 'Identification', active: true },
          { name: 'Include', value: 'Include', active: true },
          { name: 'Located', value: 'Located', active: true },
          { name: 'Participate', value: 'Participate', active: true },
          { name: 'Protect', value: 'Protect', active: true },
          { name: 'Provide', value: 'Provide', active: true },
          { name: 'Report', value: 'Report', active: true },
          { name: 'Test', value: 'Test', active: true },
          { name: 'Supply', value: 'Supply', active: true },
          { name: 'Support', value: 'Support', active: true },
          { name: 'Use', value: 'Use', active: true },
          { name: 'Job Title', value: 'JobTitle', active: true },
        ],
      },
    },
  },
  DYNAMIC_DASHBOARDS: true,
};
