import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
} from '@mui/material';
import {
  Check,
  Delete,
  ErrorOutline,
} from '@mui/icons-material';

const IconFile = ({
  fileName,
  filesToUpload,
}) => {
  let iconFile;
  const fileToUpload = _.find(filesToUpload, { name: fileName });
  if (fileToUpload && fileToUpload.loading) {
    iconFile = <CircularProgress size={15} sx={{ marginRight: '10px' }} />;
  } else if (fileToUpload && fileToUpload.success) {
    iconFile = <Check fontSize="small" sx={{ marginRight: '10px' }} />;
  } else if (fileToUpload
    && !fileToUpload.success
    && fileToUpload.success !== undefined) {
    iconFile = <ErrorOutline fontSize="small" sx={{ marginRight: '10px' }} />;
  } else {
    iconFile = (
      <Box
        variant="span"
        sx={{
          display: 'inline-block',
          marginRight: '25px',
        }}
      />
    );
  }

  return iconFile;
};

const UploadFiles = ({
  // acceptExtensions,
  handleFileChange,
  handleRemoveFile,
  filesToUpload,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexGrow="1"
      m={2}
      sx={{ gap: '8px 16px' }}
      flexDirection="column"
    >
      <Box
        ml="40px"
        display="flex"
        flexWrap="wrap"
        sx={{ gap: '8px 16px' }}
      >
        <Input
          type="file"
          sx={{ display: 'none' }}
          onChange={(event) => handleFileChange(event.target)}
          inputRef={inputRef}
          inputProps={{
            accept: `txt,log,xml,htm,html,gif,png,jpeg,jpg,doc,xls,ppt,pps,pdf,bmp,gif,
            zip,mp3,wav,mp4,ogg,avi,m4a,mpeg,mov`,
            multiple: true,
          }}
          id="file-input-upload"
        />
        <InputLabel htmlFor="file-input-upload">
          <Button variant="outlined" component="span">
            {t('form.add_files')}
          </Button>
        </InputLabel>
      </Box>
      <List dense>
        {_.map(filesToUpload, (file, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
          >
            <IconFile fileName={file.name} filesToUpload={filesToUpload} />
            {file.name}
            <IconButton
              size="small"
              onClick={() => handleRemoveFile(file)}
              disabled={file.success}
            >
              <Delete fontSize="small" />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

UploadFiles.propTypes = {
  // acceptExtensions: PropTypes.string,
  handleFileChange: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
};

UploadFiles.defaultProps = {
  // acceptExtensions: '',
  filesToUpload: [],
};

export default UploadFiles;
