const fr = {
  fr: {
    translation: {
      doc: {
        english_version: 'Version anglaise',
        original_text: 'Texte original : ',
        original_text_extract: 'Extrait du texte original : ',
        original_file: 'Fichier original : ',
        translated_file: 'Fichier traduit : ',
        file_extension: 'Extension : ',
        file_lang: 'Langue : ',
      },
      dashboard: {
        widget: {
          companies: 'Entreprises',
          count: 'Nombre',
          countries: 'Pays',
          emails: 'E-mails',
          files_extensions: 'Extensions des fichiers',
          files_languages: 'Langues des fichiers',
          ipv4: 'IPv4',
          locations: 'Localisations',
          persons: 'Personnes',
          products: 'Produits',
          risks: 'Risques',
        },
      },
    },
  },
};

export default fr;
