import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const ImgLoader = ({
  src,
  fallbackSrc,
  fallbackComponent,
  skeletonClassName,
  alt,
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const {
    width,
    height,
  } = { ...rest };

  const onError = () => {
    if (fallbackSrc !== null && fallbackSrc !== imgSrc) {
      setImgSrc(fallbackSrc);
    } else {
      setIsLoaded(true);
      setIsErrored(true);
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    setImgSrc(src);
    setIsErrored(false);
  }, [src]);

  return (
    !_.isEmpty(fallbackComponent) && isErrored ? (
      fallbackComponent
    ) : (
      <Fragment>
        {!isLoaded && (
          <Skeleton
            variant="rectangular"
            component="div"
            width={width}
            height={height}
            className={skeletonClassName}
          />
        )}
        <Zoom>
          <img
            src={imgSrc}
            style={isLoaded ? {} : { width: 0, height: 0, border: 'none' }}
            onLoad={() => setIsLoaded(true)}
            onError={onError}
            alt={alt}
            {...rest}
          />
        </Zoom>
      </Fragment>
    )
  );
};

ImgLoader.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  fallbackSrc: PropTypes.string,
  fallbackComponent: PropTypes.node,
  loading: PropTypes.string,
  skeletonClassName: PropTypes.string,
};

ImgLoader.defaultProps = {
  alt: 'Image with no alt text',
  className: '',
  fallbackSrc: null,
  fallbackComponent: null,
  loading: '',
  skeletonClassName: '',
};

export default ImgLoader;
