import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Chip,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { cleanupQuickResults, refineAddFacetsValues } from 'generic/core/search/actions';
import { OpenInNewOutlined } from '@mui/icons-material';
import { clearSelection } from 'generic/core/selection/actions';

const useStyles = makeStyles((theme) => ({
  tag: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

const Tag = ({
  tags,
  icon,
  idChamp,
  disableRefine,
  externalLink,
  color,
  title,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    tags.map((tag) => {
      const upperProps = {
        className: clsx(classes.tag),
        icon,
        key: tag,
        label: <span title={title}>{tag}</span>,
        size: 'extraSmall',
        variant: 'filledPrimary',
        sx: {},
      };

      if (color) {
        upperProps.sx.backgroundColor = `${color}45`;
      }

      if (!disableRefine) {
        upperProps.onClick = () => {
          dispatch(cleanupQuickResults());
          dispatch(clearSelection({ quickResultsScope: true }));
          dispatch(refineAddFacetsValues([{ champ: idChamp, strategie: tag }]));
        };
      }

      const type = typeof externalLink;
      if (['string', 'function'].includes(type)) {
        upperProps.deleteIcon = <OpenInNewOutlined />;
        upperProps.sx = {
          ...upperProps.sx,
          '& .MuiChip-deleteIcon': {
            color: 'primary.attenuated',
          },
          '& .MuiChip-deleteIcon:hover': {
            color: 'primary.main',
          },
        };

        if (typeof externalLink === 'string') {
          upperProps.onDelete = () => window.open(externalLink);
        } else if (typeof externalLink === 'function') {
          upperProps.onDelete = () => externalLink(tag);
        }
      }

      return (
        <Chip
          {...upperProps}
        />
      );
    })
  );
};

Tag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.element,
  idChamp: PropTypes.number.isRequired,
  disableRefine: PropTypes.bool,
  externalLink: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  color: PropTypes.string,
  title: PropTypes.string,
};

Tag.defaultProps = {
  tags: [],
  idChamp: 1000,
  disableRefine: false,
  externalLink: null,
  title: '',
};

export default Tag;
