import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  TextField,
} from 'formik-mui';
import * as Yup from 'yup';
import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const MailingListDialog = ({
  editOrCreate,
  loading,
  nameMailingList,
  open,
  handleClose,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const initialValues = {
    libelle: nameMailingList,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        libelle: Yup.string().required(t('form.errors.mandatory')),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
        >
          <Dialog
            scroll="paper"
            open={open}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle>
              {t(`mailing_lists.${editOrCreate}_dialog`)}
            </DialogTitle>
            <DialogContent
              dividers
            >
              { /* <pre>{JSON.stringify(form.values, 0, 2)}</pre> */ }
              <Field
                autoFocus
                component={TextField}
                fullWidth
                label={t('mailing_lists.name')}
                name="libelle"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.send')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

MailingListDialog.propTypes = {
  editOrCreate: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  nameMailingList: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

MailingListDialog.defaultProps = {
  loading: false,
  nameMailingList: '',
};

export default MailingListDialog;
