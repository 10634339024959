import { types } from 'generic/core/ux/actions';

const uxReducer = (
  state = {
    leftDrawerOpened: false,
    rightDrawerOpened: false,
    backPageLocation: null,
    resultsCompleteVisibleInResults: false,
  },
  action,
) => {
  switch (action.type) {
    case types.OPEN_LEFT_DRAWER: {
      return {
        ...state,
        leftDrawerOpened: true,
      };
    }
    case types.CLOSE_LEFT_DRAWER: {
      return {
        ...state,
        leftDrawerOpened: false,
      };
    }
    case types.OPEN_RIGHT_DRAWER: {
      return {
        ...state,
        rightDrawerOpened: true,
      };
    }
    case types.CLOSE_RIGHT_DRAWER: {
      return {
        ...state,
        rightDrawerOpened: false,
      };
    }
    case types.SET_BACK_PAGE_LOCATION: {
      return {
        ...state,
        backPageLocation: action.location,
      };
    }
    case types.UNSET_BACK_PAGE_LOCATION: {
      return {
        ...state,
        backPageLocation: null,
      };
    }
    case types.TOGGLE_RESULTS_COMPLETE_VISIBILITY_IN_RESULTS: {
      return {
        ...state,
        resultsCompleteVisibleInResults: action.isVisible,
      };
    }
    default:
      return state;
  }
};

export default uxReducer;
