import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  cleanupMailingLists,
  fetchMailingLists,
  openMailingListDialog,
  types,
} from 'generic/core/mailingLists/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';

import GlobalLoading from 'generic/components/pages/GlobalLoading';
import MailingLists from 'generic/components/pages/MailingLists';
import MailingListDialogContainer from 'generic/containers/anr/MailingListDialogContainer';

const MailingListsContainer = () => {
  const mailingLists = useSelector((state) => state.mailingLists.mailingLists);
  const loading = useSelector((state) => state.mailingLists.loading);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchMailingLists());

    /* Cleanup des Listes de diffusion lorsque le composant est "unmount" (donc quand on change de page) */
    return () => dispatch(cleanupMailingLists());
  }, [dispatch]);

  const handleOpenMailingListDialog = (idMailingList, nameMailingList) => {
    dispatch(openMailingListDialog(idMailingList, nameMailingList));
  };

  const handleOpenDeleteMailingListConfirmDialog = (rowsDeleted, data) => {
    const ids = [];
    const names = [];
    for (let i = 0; i < rowsDeleted.data.length; i++) {
      if (data[rowsDeleted.data[i].dataIndex]) {
        ids.push(data[rowsDeleted.data[i].dataIndex].listeDiffusion);
        names.push(data[rowsDeleted.data[i].dataIndex].libelle);
      }
    }
    dispatch(setConfirmDialogData({
      title: t('mailing_lists.delete_dialog', { count: ids.length }),
      message: (
        <div>
          {t('mailing_lists.delete', { count: ids.length })}
          <ul>
            {_.map(names, (name) => <li key={name}>{name}</li>)}
          </ul>
        </div>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_MAILING_LISTS_SUCCESS, types.DELETE_MAILING_LISTS_ERROR],
      action: {
        type: types.DELETE_MAILING_LISTS,
        ids,
      },
    }));
    return false;
  };

  if (loading) {
    return <GlobalLoading />;
  }

  return (
    <Fragment>
      <MailingLists
        mailingLists={mailingLists}
        handleDeleteMailingList={handleOpenDeleteMailingListConfirmDialog}
        handleOpenMailingListDialog={handleOpenMailingListDialog}
      />
      <MailingListDialogContainer />
    </Fragment>
  );
};

export default MailingListsContainer;
