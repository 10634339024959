import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Field } from 'formik';
import withStyles from '@mui/styles/withStyles';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

const textFieldStyle = (theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '#20292F',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#ced4da' : '#5C5C5C',
    fontFamily: 'Rajdhani, sans-serif',
    fontWeight: 'bold',
    width: '100%',

    '& > .MuiFormHelperText-root': {
      backgroundColor: theme.palette.primary.transparent,
      padding: '.25em 0 .25em .75em',
    },
  },
  input: {
    height: '1.25em',
    padding: '.25em 0 .25em .75em',
  },
});

const CustomTextField = withStyles(textFieldStyle)(({ classes, innerRef, ...rest }) => (
  <TextField
    className={classes.root}
    InputProps={{
      disableUnderline: true,
      inputProps: {
        ref: innerRef,
        className: classes.input,
      },
    }}
    variant="standard"
    margin="normal"
    size="medium"
    {...rest}
  />
));

const LoadingButtonWithIndicator = ({ ...props }) => (
  <LoadingButton
    sx={{
      fontFamily: 'Rajdhani, sans-serif',
      height: '2em',
      mt: 1,
    }}
    color="secondary"
    loadingIndicator={<CircularProgress color="primary" size={16} />}
    {...props}
  />
);

const FormLogin = ({
  variant, loading, logonError, resetPasswordLoading, resetPasswordError, toggleResetPassword,
}) => {
  const { t } = useTranslation();

  const usernameInput = useRef(null);
  const usernameOrEmailInput = useRef(null);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      sx={{ minHeight: '30vh' }}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
      >
        { variant === 'reset' && (
          <Typography variant="body1" align="center">{t('logon.forgotten_password_label')}</Typography>
        )}
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        direction="column"
      >
        {variant === 'reset'
          ? (
            <Fragment>
              <Box width="250px" mb={1}>
                <Field
                  autoFocus
                  component={CustomTextField}
                  name="emailOrLogin"
                  placeholder={t('logon.login_or_email')}
                  innerRef={usernameOrEmailInput}
                  required
                  type="text"
                />
              </Box>

              {resetPasswordError && (
                <Typography
                  color="error"
                  variant="subtitle2"
                >
                  {t('logon.forgotten_password_error')}
                </Typography>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Box width="250px">
                <Field
                  autoComplete="username"
                  autoFocus
                  component={CustomTextField}
                  name="login"
                  placeholder={t('logon.login')}
                  innerRef={usernameInput}
                  required
                  type="text"
                />
              </Box>

              <Box width="250px" mb={1}>
                <Field
                  autoComplete="current-password"
                  component={CustomTextField}
                  name="password"
                  placeholder={t('logon.password')}
                  required
                  type="password"
                />
              </Box>

              {logonError && (
                <Typography
                  color="error"
                  variant="subtitle2"
                >
                  {t('logon.fail_auth')}
                </Typography>
              )}
            </Fragment>
          )}
      </Grid>

      <Stack
        direction="column"
        spacing={1}
        width="250px"
      >
        { variant === 'reset'
          ? (
            <Fragment>
              <LoadingButtonWithIndicator
                disabled={resetPasswordLoading}
                loading={resetPasswordLoading}
                type="submit"
              >
                {t('form.send')}
              </LoadingButtonWithIndicator>

              <Button
                size="small"
                variant="text"
                onClick={() => {
                  toggleResetPassword();
                  usernameOrEmailInput.current.focus();
                }}
                sx={{
                  width: 'fit-content',
                  textTransform: 'none',
                  color: 'backgroundLogin.contrastText',
                  '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'initial',
                  },
                }}
              >
                {t('logon.forgotten_password_back')}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <LoadingButtonWithIndicator
                disabled={loading}
                loading={loading}
                type="submit"
              >
                {t('logon.to_login')}
              </LoadingButtonWithIndicator>

              <Button
                size="small"
                variant="text"
                onClick={() => {
                  toggleResetPassword();
                  usernameInput.current.focus();
                }}
                sx={{
                  width: 'fit-content',
                  textTransform: 'none',
                  color: 'backgroundLogin.contrastText',
                  px: 0,
                  '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'initial',
                  },
                }}
              >
                {t('logon.forgotten_password')}
              </Button>
            </Fragment>
          )}
      </Stack>
    </Grid>
  );
};

FormLogin.propTypes = {
  loading: PropTypes.bool.isRequired,
  logonError: PropTypes.bool.isRequired,
  resetPasswordLoading: PropTypes.bool.isRequired,
  resetPasswordError: PropTypes.bool.isRequired,
  toggleResetPassword: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['logon', 'reset']),
};

FormLogin.defaultProps = {
  variant: 'logon',
};

export default FormLogin;
