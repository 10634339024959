import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  CircularProgress,
  Input,
} from '@mui/material';
import { Image, AddPhotoAlternateOutlined } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { uploadUserImage } from 'generic/core/auth/actions';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const AvatarUpload = ({
  fileUploading,
  userId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();
  const avatarSrc = `${API_ENTRY_POINT}/utilisateur/${userId}/rawavatar?logon=${logon}&key=${key}&a=${Date.now()}`;

  const handleFileChange = (target) => {
    if (target.files && target.files.length > 0) {
      const fd = new FormData();
      fd.append('file', target.files[0]);
      dispatch(uploadUserImage(fd));
    }
  };

  return (
    <Box
      position="relative"
    >
      <Avatar
        src={avatarSrc}
        alt=""
        sx={{
          flexGrow: 1,
          width: 130,
          height: 130,
          borderRadius: '50%',
          border: '1px solid #CCCCCC',
        }}
      >
        <Image sx={{ fontSize: 80 }} />
      </Avatar>
      <Box
        position="absolute"
        bottom="0%"
        right="0%"
      >
        <TooltipButton
          title={t('profile.upload_img_user')}
          disabled={fileUploading}
          edge="start"
          size="extraSmall"
          tag="fab"
          color="primary"
          sx={{
            borderRadius: '5px',
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: 'primary.main',
              color: 'white',
            },
          }}
        >
          {fileUploading ? <CircularProgress color="inherit" size={20} /> : <AddPhotoAlternateOutlined />}
          <Input
            type="file"
            sx={{ position: 'absolute', width: '100%', opacity: 0 }}
            onChange={(event) => handleFileChange(event.target)}
            disabled={fileUploading}
            inputProps={{
              accept: '.jpg, .jpeg, .png, .tiff',
            }}
            title=""
          />
        </TooltipButton>
      </Box>
    </Box>
  );
};

AvatarUpload.propTypes = {
  fileUploading: PropTypes.bool,
  userId: PropTypes.number.isRequired,
};

AvatarUpload.defaultProps = {
  fileUploading: false,
};

export default AvatarUpload;
