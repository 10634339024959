import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  CircularProgress,
  Input,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Check, ErrorOutline } from '@mui/icons-material';

const IconFile = ({
  fileName,
  filesToUpload,
}) => {
  let iconFile;
  const fileToUpload = _.find(filesToUpload, { name: fileName });
  if (fileToUpload && fileToUpload.loading) {
    iconFile = <CircularProgress size={15} sx={{ marginRight: '10px' }} />;
  } else if (fileToUpload && fileToUpload.success) {
    iconFile = <Check fontSize="small" sx={{ marginRight: '10px' }} />;
  } else if (fileToUpload
    && !fileToUpload.success
    && fileToUpload.success !== undefined) {
    iconFile = <ErrorOutline fontSize="small" sx={{ marginRight: '10px' }} />;
  } else {
    iconFile = (
      <Box
        variant="span"
        sx={{
          display: 'inline-block',
          marginRight: '25px',
        }}
      />
    );
  }

  return iconFile;
};

const UploadFilesToCreateDocuments = ({
  acceptExtensions,
  handleFileChange,
  handleUploadClick,
  filesToUpload,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexGrow="1"
      m={2}
      sx={{ gap: '8px 16px' }}
      flexDirection="column"
    >

      <Box
        ml="40px"
        display="flex"
        flexWrap="wrap"
        sx={{ gap: '8px 16px' }}
      >
        <OutlinedInput
          type="text"
          placeholder={t('form.upload_placeholder')}
          sx={{ padding: '0' }}
          inputProps={{
            sx: { margin: '-2px 10px' },
          }}
          startAdornment={(
            <InputAdornment position="start">
              <Input
                type="file"
                sx={{ position: 'absolute', width: '100%', opacity: 0 }}
                onChange={(event) => handleFileChange(event.target)}
                inputRef={inputRef}
                inputProps={{
                  accept: acceptExtensions,
                  multiple: true,
                }}
              />
            </InputAdornment>
          )}
          endAdornment={(
            <InputAdornment position="end">
              <LoadingButton
                color="primary"
                onClick={handleUploadClick}
                disabled={_.isEmpty(filesToUpload) || _.filter(filesToUpload, (item) => !item.success).length === 0}
                loading={_.find(filesToUpload, { loading: true }) !== undefined}
                sx={{ borderRadius: '0 4px 4px 0' }}
              >
                {t('form.upload')}
              </LoadingButton>
            </InputAdornment>
          )}
        />
      </Box>

      <List dense>
        {_.map(filesToUpload, (file, index) => (
          <ListItem
            alignItems="flex-start"
            key={index}
          >
            <IconFile fileName={file.name} filesToUpload={filesToUpload} />
            {file.name}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

UploadFilesToCreateDocuments.propTypes = {
  acceptExtensions: PropTypes.string,
  handleFileChange: PropTypes.func.isRequired,
  handleUploadClick: PropTypes.func.isRequired,
  filesToUpload: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
};

UploadFilesToCreateDocuments.defaultProps = {
  acceptExtensions: '',
  filesToUpload: [],
};

export default UploadFilesToCreateDocuments;
