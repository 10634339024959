import React from 'react';
import { Box, getContrastRatio } from '@mui/material';
import { CONSTANTS } from 'generic/core/constants';
import FormLoginContainer from 'generic/containers/auth/FormLoginContainer';
import FormResetPasswordConfrimationContainer from 'generic/containers/auth/FormResetPasswordConfrimationContainer';

import { overrideRessource } from 'generic/utils/utils';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTheme } from '@mui/styles';

const { PROJECT_NAME } = CONSTANTS;

const logoWhite = overrideRessource('img/login-logo.svg', true);
const logoBlack = overrideRessource('img/login-logo-black.svg', true);

const AuthenticationLanding = () => {
  const { path } = useRouteMatch();
  const theme = useTheme();

  const backgroundLogin = theme.palette.backgroundLogin.main;
  const activeLogo = getContrastRatio(backgroundLogin, '#fff') > 3 ? logoWhite : logoBlack;

  return (
    <Box
      color="backgroundLogin.contrastText"
      bgcolor="backgroundLogin.main"
      height="100vh"
      padding="16px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexGrow="1"
      rowGap={2}
    >
      <Box
        component="img"
        src={activeLogo}
        alt={PROJECT_NAME}
        sx={{ ...theme?.custom?.login?.logo || '' }}
      />
      <Switch>
        <Route path={`${path}/reset-password-confirmation`}>
          <FormResetPasswordConfrimationContainer />
        </Route>
        <Route>
          <FormLoginContainer />
        </Route>
      </Switch>
    </Box>
  );
};

export default AuthenticationLanding;
