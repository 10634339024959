import _ from 'lodash';

let customPath = process.env.REACT_APP_CUSTOM_PATH;
// Sur l'environnement de dev, on peut surcharger le REACT_APP_CUSTOM_PATH de l'"env"
// qui a été démarré via le sessionStorage ou le localStorage
if (process.env.NODE_ENV === 'development') {
  if (sessionStorage.getItem('customPath') !== null) {
    // Si on précise le customPath dans le sessionStorage
    // celui-ci permet de surcharger le REACT_APP_CUSTOM_PATH
    customPath = sessionStorage.getItem('customPath');
  } else if (localStorage.getItem('customPath') !== null) {
    // Si jamais le sessionStorage n'est pas setté, le localStorage permet également
    // de surcharger le REACT_APP_CUSTOM_PATH
    customPath = localStorage.getItem('customPath');
  }
}
export const CUSTOM_PATH = customPath || '';

const langMapping = {
  1: 'fr',
  2: 'en',
};

let constants = {
  APP_COLORS: {
    primary: '#227781',
    secondary: '#4E4E89',
    primaryDark: '#40EBFF',
    secondaryDark: '#40EBFF',
    outlinedBorderColor: 'rgba(0, 0, 0, 0.23)',
    outlinedBorderColorDark: 'rgba(255, 255, 255, 0.23)',
    background: '#FFFFFF',
    backgroundDark: '#222B32',
    backgroundHeaderDark: '#182128',
  },

  LANG_MAPPING: langMapping,
  BYPASS_AUTH: process.env.REACT_APP_BYPASS_AUTH === 'true',
  FORBIDDEN_CHARS: ['~', ';', ',', '|', '\t'],
  PROJECT_NAME: process.env.REACT_APP_PROJECT_NAME,
  MAIN_PAGE: process.env.REACT_APP_MAIN_PAGE,
  DEFAULT_MAX_SIDE_CHILDREN_WIDTH: 3,
  EMAIL_REGEX: /^(?!.*@[^,]*,)/,

  CK_EDITOR_CONFIG: {
    toolbar: {
      items: [
        'heading',
        '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList',
        '|', 'imageUpload', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',
        '|', 'undo', 'redo',
        '|', 'selectAll',
      ],
      shouldNotGroupWhenFull: true,
    },
    language: langMapping[_.get(window, 'QWAM.localeId', 1)],
  },
};

if (customPath) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: customConstants } = require(`custom/${customPath}/core/constants`);
    constants = _.merge(constants, customConstants);
  } catch (err) {
    console.info('Pas de fichier custom de constantes global');
  }
}

export const CONSTANTS = constants;
