import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { Select, TextField } from 'formik-mui';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';

const FormDashboard = ({
  dialogTitle,
  handleClose,
  handleSubmit,
  initialValues,
  loading,
  userIsAdmin,
  userId,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        libelle: Yup.string().required(t('form.errors.mandatory')),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form
          onKeyPress={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && !loading) {
              form.handleSubmit();
            }
          }}
        >
          <Dialog
            PaperProps={{
              sx: { minHeight: '60%' },
            }}
            fullWidth
            scroll="paper"
            maxWidth="lg"
            open
          >
            <DialogTitle>
              {dialogTitle}
            </DialogTitle>
            <DialogContent
              dividers
            >
              <Box flexGrow="1">
                <Field
                  component={TextField}
                  autoComplete="off"
                  fullWidth
                  sx={{ mb: 2 }}
                  name="libelle"
                  label={t('dashboard.dashboard_title')}
                  required
                  autoFocus
                />
              </Box>
              {userIsAdmin && (
                <Field
                  component={Select}
                  name="utilisateur"
                  label={t('dashboard.type')}
                  sx={{ width: '100%', mb: 2 }}
                  onClose={_.noop}
                >
                  <MenuItem
                    value={userId}
                  >
                    {t('ui.mine')}
                  </MenuItem>
                  <MenuItem
                    value={1000}
                  >
                    {t('ui.shared')}
                  </MenuItem>
                </Field>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('ui.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={loading || !form.isValid}
                loading={loading}
              >
                {t('form.save')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

FormDashboard.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  loading: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
};

FormDashboard.defaultProps = {
  loading: false,
};

export default FormDashboard;
