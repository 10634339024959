const themeConfig = {
  LIGHT: {
    THEME: {
      custom: {
        logo: {
          height: '20px',
        },
        login: {
          logo: {
            height: '64px',
          },
        },
      },
    },
  },
};

export default themeConfig;
