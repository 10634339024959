/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { CheckBoxOutlined, IndeterminateCheckBoxOutlined } from '@mui/icons-material';
import _ from 'lodash';
import i18next from 'i18next';
import { CONSTANTS, CUSTOM_PATH } from 'generic/core/constants';
import { getContrastRatio, lighten } from '@mui/material';

// Site intéressant pour choisir des couleurs "associables" :
// https://www.canva.com/colors/color-wheel/

/* ------------ Complémentaires ------------ */
// Bleu et orange classique
// const primary = '#407ec9';
// const secondary = '#f3681f';

// Violet et jaune
// const primary = '#3d3d6b';
// const secondary = '#fdc431';

// Turquoise orange
// const primary = '#2a7b9b';
// const secondary = '#f58c2d';

// Bleu saumon pastel
// const primary = '#3a97a9';
// const secondary = '#cd7e6f';

/* ------------ Analogues ------------ */
// Bleu et Violet
// const primary = '#2E4A62';
// const secondary = '#4E6B89';

const {
  primary,
  secondary,
  primaryDark,
  secondaryDark,
  outlinedBorderColor,
  outlinedBorderColorDark,
  background,
  backgroundDark,
  backgroundHeaderDark,
  forcedBackgroundHeader,
} = CONSTANTS.APP_COLORS;

const backgroundHeader = forcedBackgroundHeader || primary;
const backgroundLogin = primary;
const backgroundLoginDark = backgroundDark;
const spacing = 8;

let THEME = {
  // LIGHT MODE
  LIGHT: {
    THEME: {
      components: {
        MuiToolbar: {
          styleOverrides: {
            root: {
              minHeight: '64px',
              '& .MuiMenuItem-root': {
                borderRadius: '5px',
              },
            },
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'outlined',
            margin: 'dense',
            size: 'small',
            autoComplete: 'off',
          },
        },
        MuiSelect: {
          defaultProps: {
            variant: 'outlined',
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              '& .MuiIconButton-root': {
                padding: '4px',
              },
            },
          },
        },
        MuiInputLabel: {
          defaultProps: {
            shrink: true,
          },
          styleOverrides: {
            root: {
              fontSize: '1.2em',
              lineHeight: '1.4em',
            },
          },
        },
        // style des inputs outlined
        MuiOutlinedInput: {
          defaultProps: {
            notched: true,
            size: 'small',
          },
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: outlinedBorderColor,
                '& legend': {
                  fontSize: '0.9em',
                },
              },
            },
          },
        },
        // style des Icônes des ListItem
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: 'auto',
              marginRight: '10px',
              minHeight: '22px',
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            root: {
              maxHeight: '500px',
            },
          },
        },
        // style des Icônes des ListItem dans le contexte
        // d'un menu
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '& .MuiListItemIcon-root': {
                minWidth: 'auto',
                marginRight: '10px',
              },
            },
          },
        },
        // style des "toasts"/tooltips
        MuiTooltip: {
          defaultProps: {
            arrow: true,
          },
          styleOverrides: {
            tooltip: {
              fontSize: '1em',
              maxWidth: '600px',
            },
          },
        },
        // style des cards
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: `0 2px 4px -2px rgb(0 0 0 / 24%),
                0 4px 5px -2px rgb(0 0 0 / 20%),
                0px -1px 3px 0px rgb(0 0 0 / 12%)`,
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            title: {
              fontWeight: 'bold',
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            scrollButtons: {
              '&.Mui-disabled': {
                opacity: 0.3,
              },
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              minHeight: '48px',
            },
          },
        },
        // style des labels des inputs
        MuiFormLabel: {
          styleOverrides: {
            root: {
              color: '#333333',
              fontSize: '1.1em',
            },
          },
        },
        // style des labels des autres inputs (checkbox, switch...)
        MuiFormControl: {
          styleOverrides: {
            root: {
              width: '100%',
              marginTop: spacing,
              marginBottom: spacing * 0.5,
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: '1em',
            },
          },
        },
        // style des checkbox
        MuiCheckbox: {
          defaultProps: {
            // eslint-disable-next-line react/jsx-filename-extension
            checkedIcon: <CheckBoxOutlined />,
            indeterminateIcon: <IndeterminateCheckBoxOutlined />,
          },
          styleOverrides: {
            root: {
              margin: '0px 0px 3px 10px',
              padding: '5px',
            },
          },
        },
        MUIDataTable: {
          styleOverrides: {
            paper: {
              '@media (min-width: 1200px)': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              },
            },
            responsiveBase: {
              flexGrow: '1',
            },
          },
        },
        MUIDataTableHeadRow: {
          styleOverrides: {
            root: {
              position: 'relative',
              zIndex: 1300,
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              flexShrink: 0,
              '@media (max-width: 1200px)': {
                position: 'sticky',
                top: 0,
                backgroundColor: background,
                zIndex: 1300,
              },
            },
            actions: {
              flex: '0',
              display: 'flex',
            },
          },
        },
        MUIDataTableToolbarSelect: {
          styleOverrides: {
            root: {
              flex: '0',
              minHeight: '64px',
              padding: '0',
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              '@media (max-width: 1200px)': {
                position: 'sticky',
                bottom: 0,
                backgroundColor: background,
                zIndex: 1100,
              },
            },
          },
        },
        MuiTypography: {
          variants: [
            {
              props: { variant: 'h6' },
              style: {
                fontSize: '1rem',
                lineHeight: 'initial',
              },
            },
          ],
        },
        // Style des boutons
        MuiButton: {
          styleOverrides: {
            root: {
              '&.MuiButtonGroup-groupedOutlined.Mui-disabled': {
                border: '1px solid rgba(0, 0, 0, 0.26)',
              },
              '&.MuiButtonGroup-grouped:not(:last-of-type):not(.Mui-disabled):not(.MuiButton-outlined)': {
                borderColor: 'white',
              },
            },
            endIcon: {
              margin: '0 -6px 0 4px',
            },
          },
          defaultProps: {
            variant: 'contained',
            color: 'primary',
            disableElevation: true,
          },
          variants: [
            {
              props: { size: 'extraSmall' },
              style: {
                padding: '2px 8px',
                '& svg': {
                  fontSize: '18px',
                },
              },
            },
          ],
        },
        MuiLoadingButton: {
          defaultProps: {
            variant: 'contained',
            color: 'primary',
            disableElevation: true,
          },
        },
        MuiFab: {
          styleOverrides: {
            root: {
              boxShadow: `0px 3px 5px -1px rgb(255 255 255 / 52%),
                0px 6px 10px 0px rgb(255 255 255 / 33%),
                0px 1px 18px 0px rgb(255 255 255 / 52%)`,
              '&:active': {
                boxShadow: `0px 3px 5px -1px rgb(255 255 255 / 52%),
                0px 6px 10px 0px rgb(255 255 255 / 33%),
                0px 1px 18px 0px rgb(255 255 255 / 52%)`,
              },
            },
          },
          variants: [
            {
              props: { size: 'extraSmall' },
              style: {
                height: '28px',
                minHeight: 'auto',
                width: '28px',
                '& svg': {
                  fontSize: '20px',
                },
              },
            },
          ],
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: '#0782C1',
            },
          },
        },
        // Style des chips
        MuiChip: {
          styleOverrides: {
            root: {
              height: 'auto',
              minHeight: '32px',
            },
            label: {
              whiteSpace: 'normal',
            },
          },
          variants: [
            {
              props: { variant: 'filledPrimary' },
              style: {
                backgroundColor: `${primary}20`,
              },
            },
            {
              props: { variant: 'filledSecondary' },
              style: {
                backgroundColor: `${secondary}20`,
              },
            },
            {
              props: { size: 'extraSmall' },
              style: {
                minHeight: '20px',
                '& .MuiChip-icon': {
                  marginLeft: '4px',
                  marginRight: '-4px',
                  fontSize: '14px',
                },
                '& .MuiChip-label': {
                  paddingLeft: '8px',
                  paddingRight: '8px',
                },
                '& .MuiChip-deleteIcon': {
                  fontSize: '14px',
                },
              },
            },
          ],
        },
      },
      palette: {
        mode: 'light',
        primary: {
          main: primary,
          transparent: `${primary}20`,
          attenuated: `${primary}80`,
        },
        header: {
          main: backgroundHeader,
          menuSelected: primary,
        },
        backgroundLogin: {
          main: backgroundLogin,
          light: lighten(backgroundLogin, 0.2),
          contrastText: getContrastRatio(backgroundLogin, '#fff') > 3 ? '#fff' : '#000',
        },
        text: {
          neutral: 'rgba(0, 0, 0, 0.54)',
          disabled: 'rgba(0, 0, 0, 0.38)',
        },
        secondary: {
          main: secondary,
        },
        // Couleurs utiles aux éléments associés aux réseaux sociaux
        dailymotion: {
          main: '#0166cc',
        },
        facebook: {
          main: '#1877f2',
        },
        linkedin: {
          main: '#0a66c2',
        },
        twitter: {
          main: '#55acee',
        },
        whatsapp: {
          main: '#3fc252',
        },
        youtube: {
          main: '#ef462c',
        },
      },
      // Valeur a modifier pour "aérer" ou "densifier" l'interface
      // si la valeur est 8 (par exemple), l'unité de "spacing" sera
      // de 8px (donc theme.spacing(2) vaudra 2*8px soit 16px)
      spacing,
      custom: {
        // style du logo dans le Header
        logo: {
          height: '38px',
          marginRight: '8px',
        },
        // style des mots highlités dans le search
        highlightKeyword: {
          fontWeight: 'bold',
          color: primary,
        },
      },
    },
    GLOBAL_STYLES: {
      '@global': {
        ':root': {
          '--ck-color-toolbar-background': '#fff',
          '--ck-color-list-background': '#fff',
          '--ck-color-dropdown-panel-background': '#fff',
          '--ck-color-list-button-on-text': '#fff',
          '--ck-color-base-text': '#333',
          '--ck-color-button-on-color': '#2977ff',
          '--ck-color-button-on-background': '#f0f7ff',
          '--ck-color-button-default-hover-background': '#f0f0f0',
          '--ck-color-button-default-active-background': '#f0f0f0',
          '--ck-color-button-on-hover-background': '#dbecff',
          '--ck-color-button-on-active-background': '#dbecff',
        },
        '.react-grid-item > .react-resizable-handle::after': {
          borderColor: 'rgba(0, 0, 0, 0.4);',
        },
        '.react-grid-item.react-draggable-dragging, .react-grid-item.resizing': {
          zIndex: 1401,
        },
        '.react-grid-item.react-grid-placeholder.placeholder-resizing': {
          zIndex: 1402,
        },
        '.react-draggable-transparent-selection .dashboard-footer': {
          display: 'none',
        },
        // bordure autour des fieldsets des formulaires
        '.formFieldset': {
          borderColor: outlinedBorderColor,
          borderRadius: '5px',
          borderStyle: 'solid',
          borderWidth: '1px',
          boxSizing: 'border-box',
          // override du style des legends des fieldsets
          '& > legend': {
            marginLeft: '8px',
            paddingRight: '5px',
            paddingLeft: '5px',
            fontSize: '0.9em',
            '& .MuiFormControlLabel-root': {
              '& .MuiFormControlLabel-label': {
                fontSize: '1em',
              },
            },
          },
        },
        '[data-rmiz-modal-overlay="visible"]': {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        blockquote: {
          borderLeft: '5px solid #ccc',
          fontStyle: 'italic',
          marginLeft: '0',
          marginRight: '0',
          overflow: 'hidden',
          paddingLeft: '1.5em',
          paddingRight: '1.5em',
        },
        '.stickyHeader': {
          position: 'sticky',
          top: '0',
          backgroundColor: background,
        },
        '.bottomBtnsContainer': {
          padding: '8px',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: background,
          position: 'sticky',
          zIndex: '1200',
          bottom: '0',
        },
        '#add-to-item-datatable .MuiToolbar-root': {
          '& .MuiFormControl-fullWidth.MuiTextField-root': {
            flex: '1 0',
          },
          '& .MuiButtonBase-root': {
            display: 'none',
          },
        },
        // styles des snackbar notistack
        '.SnackbarContent-root.SnackbarItem-contentRoot': {
          flexWrap: 'nowrap',
        },
        '#notistack-snackbar.SnackbarItem-message': {
          '@media (min-width: 600px)': {
            maxWidth: '400px',
          },
        },
        '.SnackbarItem-action .MuiButton-root': {
          color: 'white',
          minWidth: 'auto',
        },
        // style des inputs de date ayant cette classe
        '.datePickerInput': {
          width: '200px',
          paddingRight: '0',
        },
        'input[readonly]': {
          color: 'rgba(0, 0, 0, 0.38)',
          cursor: 'default',
        },
        '.inputLabelWithHelpIcon': {
          pointerEvents: 'auto',
          display: 'flex',
          alignItems: 'center',
          '& .MuiSvgIcon-root': {
            marginLeft: '8px',
          },
        },
        '.yellowStar': {
          color: '#fdd835',
          '& path': {
            stroke: '#757575',
          },
        },
        '.leftColumnAnRBtn': {
          width: '100%',
          borderRadius: 'unset',
        },
        '.height100': {
          height: '100%',
        },
        '.overflowAuto': {
          overflow: 'auto',
        },
        '.desktopOnlyOverflow': {
          '@media (min-width: 1200px)': {
            overflow: 'auto',
          },
        },
        '.padding0': {
          padding: '0',
        },
        '.width100': {
          width: '100%',
        },
        '.minWidthAuto': {
          minWidth: 'auto',
        },
        '.flexShrink0': {
          flexShrink: 0,
        },
        '.flexGrow1': {
          flexGrow: 1,
        },
        '.flexDirectionColumn': {
          flexDirection: 'column',
        },
        '.displayBlock': {
          display: 'block',
        },
        '.displayFlex': {
          display: 'flex',
        },
        '.alignItemsCenter': {
          alignItems: 'center',
        },
        '.justifyContentSpaceBetween': {
          justifyContent: 'space-between',
        },
        '.cursorPointer': {
          cursor: 'pointer',
        },
        '.positionRelative': {
          position: 'relative',
        },
        '.react-transform-wrapper': {
          width: '100%!important',
          '& .react-transform-component': {
            width: '100%',
            '& > div': {
              width: '100%',
            },
          },
        },
        // Style des tags dans le html des documents
        // Sélecteur '.html-with-tags > .html-tag' pour n'afficher que les annotations les plus "hautes"
        // Sélecteur '.html-tag:not(:has(span))' pour n'afficher que les annotations les plus "profondes"
        '.html-with-tags > .html-tag': {
          backgroundColor: 'rgb(211, 211, 211)',
          border: '1px solid #ccc',
          borderRadius: '2px',
          padding: '3px',
          '&::after': {
            verticalAlign: 'super',
            fontSize: '80%',
            paddingLeft: '3px',
            content: 'attr(type-text)',
          },
          '&.Concept, &.Coldconcept': {
            backgroundColor: '#2196F320',
          },
          '&.Product': {
            backgroundColor: '#E91E6320',
          },
          '&.Person': {
            backgroundColor: '#6a66cc20',
          },
          '&.Location': {
            backgroundColor: '#cb5d9e20',
          },
          '&.Company': {
            backgroundColor: '#7893f520',
          },
          '&.Media': {
            backgroundColor: '#b3781b20',
          },
          '&.Organization': {
            backgroundColor: '#2f590a20',
          },
          '&.Organonoff': {
            backgroundColor: '#4CAF5020',
          },
          '&.Event': {
            backgroundColor: '#FFC10720',
          },
        },
      },
    },
    HIGHCHARTS: {
      // useful palettes website : https://coolors.co/palettes/popular/orange,gradient
      colors: [
        '#264653',
        '#287271',
        '#2A9D8F',
        '#8AB17D',
        '#BABB74',
        '#E9C46A',
        '#EFB366',
        '#F4A261',
        '#EE8959',
        '#E76F51',
      ],
      entitiesColors: [
        '#F19C9C',
        '#5CBF50',
        '#31A6F3',
        '#FF6752',
        '#049698',
        '#B41A59',
        '#046084',
        '#E0D53B',
        '#FF9737',
        '#7F62B5',
      ],
      relationsEntitiesColors: [
        '#006064',
        '#E91E63',
        '#ff7777',
        '#2196F3',
        '#52a5af',
        '#6a66cc',
        '#cb5d9e',
        '#4CAF50',
        '#FFC107',
        '#7893f5',
        '#b3781b',
        '#2f590a',
        '#ff0000',
        '#00ff00',
        '#0000ff',
      ],
      chart: {
        backgroundColor: background,
        style: {
          color: '#000000',
          fontFamily: 'Open Sans',
        },
      },
      title: {
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'normal',
          fontSize: '18px',
          color: '#333333',
        },
      },
      subtitle: {
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'normal',
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'bottom',
        itemStyle: {
          color: '#333333',
        },
        itemHoverStyle: {
          color: '#000000',
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1,
        labels: {
          style: {
            color: '#666666',
          },
        },
      },
      yAxis: {
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1,
        labels: {
          style: {
            color: '#666666',
          },
        },
      },
      mapNavigation: {
        buttonOptions: {
          theme: {
            fill: '#f7f7f7',
            stroke: '#cccccc',
            style: {
              color: '#333333',
            },
            states: {
              hover: {
                fill: '#e6e6e6',
              },
            },
          },
        },
      },
      navigation: {
        menuStyle: {
          background,
        },
        menuItemStyle: {
          color: '#000000',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            symbolStroke: '#666666',
            theme: {
              fill: background,
              stroke: 'none',
              states: {
                hover: {
                  fill: '#e6e6e6',
                },
                select: {
                  fill: '#e6e6e6',
                },
              },
            },
          },
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            color: '#000000',
            connectorColor: '#C5c5C5',
          },
        },
        column: {
          borderWidth: 1,
        },
        bar: {
          borderWidth: 1,
        },
        sunburst: {
          borderWidth: 1,
        },
        treemap: {
          borderWidth: 1,
        },
      },
      lang: {
        months: [
          i18next.t('commons.months.january'),
          i18next.t('commons.months.february'),
          i18next.t('commons.months.march'),
          i18next.t('commons.months.april'),
          i18next.t('commons.months.may'),
          i18next.t('commons.months.june'),
          i18next.t('commons.months.july'),
          i18next.t('commons.months.august'),
          i18next.t('commons.months.september'),
          i18next.t('commons.months.october'),
          i18next.t('commons.months.november'),
          i18next.t('commons.months.december'),
        ],
        shortMonths: [
          i18next.t('commons.short_months.jan'),
          i18next.t('commons.short_months.feb'),
          i18next.t('commons.short_months.mar'),
          i18next.t('commons.short_months.apr'),
          i18next.t('commons.short_months.may'),
          i18next.t('commons.short_months.jun'),
          i18next.t('commons.short_months.jul'),
          i18next.t('commons.short_months.aug'),
          i18next.t('commons.short_months.sep'),
          i18next.t('commons.short_months.oct'),
          i18next.t('commons.short_months.nov'),
          i18next.t('commons.short_months.dec'),
        ],
        weekdays: [
          i18next.t('commons.weekdays.sunday'),
          i18next.t('commons.weekdays.monday'),
          i18next.t('commons.weekdays.tuesday'),
          i18next.t('commons.weekdays.wednesday'),
          i18next.t('commons.weekdays.thursday'),
          i18next.t('commons.weekdays.friday'),
          i18next.t('commons.weekdays.saturday'),
        ],
        contextButtonTitle: i18next.t('dashboard.context_button_title'),
        downloadCSV: i18next.t('dashboard.download_csv'),
        downloadJPEG: i18next.t('dashboard.download_jpeg'),
        downloadPDF: i18next.t('dashboard.download_pdf'),
        downloadPNG: i18next.t('dashboard.download_png'),
        downloadSVG: i18next.t('dashboard.download_svg'),
        downloadXLS: i18next.t('dashboard.download_xls'),
        exitFullscreen: i18next.t('dashboard.exit_full_screen'),
        printChart: i18next.t('dashboard.print_chart'),
        viewFullscreen: i18next.t('dashboard.view_full_screen'),
        viewData: i18next.t('dashboard.view_data'),
      },
    },
  },
  // DARK MODE
  DARK: {
    THEME: {
      components: {
        // style des inputs outlined
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: outlinedBorderColorDark,
              },
            },
          },
        },
        // style des cards
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: `0 2px 4px -2px rgb(0 0 0 / 24%),
                0 4px 5px -2px rgb(0 0 0 / 20%),
                0px -1px 3px 0px rgb(0 0 0 / 12%)`,
            },
          },
        },
        // style des labels des inputs
        MuiFormLabel: {
          styleOverrides: {
            root: {
              color: '#F2F2F2',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundImage: 'unset',
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              '@media (max-width: 1200px)': {
                backgroundColor: backgroundDark,
              },
            },
          },
        },

        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              '@media (max-width: 1200px)': {
                backgroundColor: backgroundDark,
              },
            },
          },
        },
        // Style des boutons
        MuiButton: {
          styleOverrides: {
            root: {
              '&.MuiButtonGroup-groupedOutlined.Mui-disabled': {
                border: '1px solid rgba(255, 255, 255, 0.26)',
              },
              '&.MuiButtonGroup-grouped:not(:last-of-type):not(.Mui-disabled):not(.MuiButton-outlined)': {
                borderColor: 'black',
              },
            },
          },
        },
        MuiFab: {
          styleOverrides: {
            root: {
              boxShadow: 'unset',
              '&:active': {
                boxShadow: 'unset',
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: '#40ebff',
            },
          },
        },
        // Style des chips
        MuiChip: {
          variants: [
            {
              style: {
                backgroundColor: `${primaryDark}80`,
              },
            },
            {
              style: {
                backgroundColor: `${secondaryDark}80`,
              },
            },
          ],
        },
      },
      palette: {
        mode: 'dark',
        primary: {
          main: primaryDark,
          transparent: `${primaryDark}20`,
          attenuated: `${primaryDark}80`,
        },
        header: {
          main: backgroundHeaderDark,
          menuSelected: backgroundHeaderDark,
        },
        backgroundLogin: {
          main: backgroundLoginDark,
          light: lighten(backgroundLoginDark, 0.2),
          contrastText: getContrastRatio(backgroundLoginDark, '#fff') > 3 ? '#fff' : '#000',
        },
        text: {
          neutral: 'rgba(255, 255, 255, 0.54)',
          disabled: 'rgba(255, 255, 255, 0.38)',
        },
        secondary: {
          main: secondaryDark,
        },
        background: {
          paper: backgroundDark,
          default: backgroundDark,
        },
        // Couleurs utiles aux éléments associés aux réseaux sociaux
        dailymotion: {
          main: '#0166cc',
        },
        facebook: {
          main: '#1877f2',
        },
        linkedin: {
          main: '#0a66c2',
        },
        twitter: {
          main: '#55acee',
        },
        whatsapp: {
          main: '#3fc252',
        },
        youtube: {
          main: '#ef462c',
        },
      },
      custom: {
        // style des mots highlités dans le search
        highlightKeyword: {
          color: primaryDark,
        },
      },
    },
    GLOBAL_STYLES: {
      '@global': {
        ':root': {
          '--ck-color-toolbar-background': backgroundDark,
          '--ck-color-list-background': backgroundDark,
          '--ck-color-dropdown-panel-background': backgroundDark,
          '--ck-color-list-button-on-text': backgroundDark,
          '--ck-color-base-text': '#ccc',
          '--ck-color-button-on-color': '#dbecff',
          '--ck-color-button-on-background': '#4d4d4d',
          '--ck-color-button-default-hover-background': '#3a3a3a',
          '--ck-color-button-default-active-background': '#3a3a3a',
          '--ck-color-button-on-hover-background': '#2977ff',
          '--ck-color-button-on-active-background': '#2977ff',
        },
        'rect.highcharts-button-box': {
          strokeWidth: 0,
        },
        '.react-grid-item > .react-resizable-handle::after': {
          borderColor: 'rgba(255, 255, 255, 0.4);',
        },
        '& .ck-editor__main': {
          color: '#000',
        },
        '& .ck-editor__main a': {
          color: '#0000EE',
        },
        // bordure autour des fieldsets des formulaires
        '.formFieldset': {
          borderColor: outlinedBorderColorDark,
        },
        '[data-rmiz-modal-overlay="visible"]': {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        blockquote: {
          borderLeft: '5px solid #ccc',
        },
        '.stickyHeader': {
          backgroundColor: backgroundDark,
        },
        '.bottomBtnsContainer': {
          borderTop: '1px solid rgba(255, 255, 255, 0.12)',
          backgroundColor: backgroundDark,
        },
        '.SnackbarItem-action .MuiButton-root': {
          color: 'white',
          minWidth: 'auto',
        },
        'input[readonly]': {
          color: 'rgba(255, 255, 255, 0.38)',
        },
        // Style des tags dans le html des documents
        // Sélecteur '.html-with-tags > .html-tag' pour n'afficher que les annotations les plus "hautes"
        // Sélecteur '.html-tag:not(:has(span))' pour n'afficher que les annotations les plus "profondes"
        '.html-with-tags > .html-tag': {
          backgroundColor: 'rgb(110, 110, 110)',
        },
      },
    },
    HIGHCHARTS: {
      // useful palettes website : https://coolors.co/palettes/popular/orange,gradient
      colors: [
        '#527D93',
        '#56A0A1',
        '#56C2B5',
        '#A8C9A0',
        '#DBDF8E',
        '#F4D18A',
        '#F4CB8A',
        '#F6B88B',
        '#F2A37D',
        '#F19478',
      ],
      entitiesColors: [
        '#F19C9C',
        '#5CBF50',
        '#31A6F3',
        '#FF6752',
        '#049698',
        '#B41A59',
        '#046084',
        '#E0D53B',
        '#FF9737',
        '#7F62B5',
      ],
      relationsEntitiesColors: [
        '#006064',
        '#E91E63',
        '#ff7777',
        '#2196F3',
        '#52a5af',
        '#6a66cc',
        '#cb5d9e',
        '#4CAF50',
        '#FFC107',
        '#7893f5',
        '#b3781b',
        '#2f590a',
        '#ff0000',
        '#00ff00',
        '#0000ff',
      ],
      chart: {
        backgroundColor: backgroundDark,
        style: {
          color: '#ffffff',
        },
      },
      title: {
        style: {
          color: '#ffffff',
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'bottom',
        itemStyle: {
          color: '#c8c8c8',
        },
        itemHoverStyle: {
          color: '#ffffff',
        },
      },
      xAxis: {
        gridLineColor: '#dddddd',
        lineColor: '#dddddd',
        minorGridLineColor: '#dddddd',
        tickColor: '#dddddd',
        labels: {
          style: {
            color: '#ffffff',
          },
        },
      },
      yAxis: {
        gridLineColor: '#dddddd',
        lineColor: '#dddddd',
        minorGridLineColor: '#dddddd',
        tickColor: '#dddddd',
        labels: {
          style: {
            color: '#ffffff',
          },
        },
      },
      mapNavigation: {
        buttonOptions: {
          theme: {
            fill: backgroundDark,
            stroke: '#aaaaaa',
            style: {
              color: '#ffffff',
            },
            states: {
              hover: {
                fill: '#666666',
              },
            },
          },
        },
      },
      navigation: {
        menuStyle: {
          background: backgroundDark,
        },
        menuItemStyle: {
          color: '#ffffff',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            symbolStroke: '#ffffff',
            theme: {
              fill: backgroundDark,
              stroke: '#ffffff',
              states: {
                hover: {
                  fill: '#666666',
                },
                select: {
                  fill: '#666666',
                },
              },
            },
          },
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            color: '#ffffff',
            connectorColor: '#e8e8e8',
          },
        },
        column: {
          borderWidth: 0,
        },
        bar: {
          borderWidth: 0,
        },
        sunburst: {
          borderWidth: 0,
        },
        treemap: {
          borderWidth: 0,
        },
      },
    },
  },
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: CUSTOM_THEME_CONFIG } = require(`custom/${CUSTOM_PATH}/core/theme`);
    THEME = _.merge(THEME, CUSTOM_THEME_CONFIG);
  } catch (err) {
    console.info('Pas de fichier custom de theme');
  }
}

const THEME_CONFIG = THEME;
export default THEME_CONFIG;
