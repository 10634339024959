const en = {
  en: {
    translation: {
      doc: {
        english_version: 'English Version',
        original_text: 'Original text: ',
        original_text_extract: 'Original text extract: ',
        original_file: 'Original file: ',
        translated_file: 'Translated file: ',
        file_extension: 'Extension: ',
        file_lang: 'Language: ',
      },
      dashboard: {
        widget: {
          companies: 'Companies',
          count: 'Count',
          countries: 'Countries',
          emails: 'Emails',
          files_extensions: 'Files extensions',
          files_languages: 'Files languages',
          ipv4: 'Ipv4',
          locations: 'Locations',
          persons: 'Persons',
          products: 'Products',
          risks: 'Risks',
        },
      },
    },
  },
};

export default en;
