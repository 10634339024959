import {
  put,
  call,
  takeLatest,
  select,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';
import {
  types,
  fetchDocumentSuccess,
  fetchDocumentError,
  saveDocumentSuccess,
  saveDocumentError,
  deleteDocumentsSuccess,
  deleteDocumentsError,
  updateStatusDocumentsSuccess,
  updateStatusDocumentsError,
} from 'generic/core/ged/actions';
import {
  createDocument,
  deleteDocumentsFromResults,
  getDocument,
  saveDocument,
  unlockDocument,
  updateStatusFromResults,
} from 'generic/api/ged';
import { snackActions } from 'generic/utils/snackbar';
import { clearSelection, clearSingleItem } from 'generic/core/selection/actions';
import { cleanupResultsComplete, refreshResults } from 'generic/core/search/actions';
import { getActiveMovementId, getActiveSelectionItems } from 'generic/utils/qesUtils';

function* workFetchDocument({ id, base, variant }) {
  try {
    const uriParams = { base };
    if (variant === 'light') {
      uriParams.gedrapide = 'True';
    }

    if (id) {
      uriParams.article = id;
    }

    const results = yield call(getDocument, { uriParams });
    yield put(fetchDocumentSuccess(results));
    if (results.userAccesConcurrent !== '') {
      const msg = i18next.t('ged.warning_concurrent_access', { user: results.userAccesConcurrent });
      snackActions.warning(msg, { persist: true });
    }
  } catch (response) {
    yield put(fetchDocumentError(response));
    console.error(response);
    snackActions.error(i18next.t('ged.error_fetching_document'));
  }
}

function* watchFetchDocument() {
  yield takeLatest(types.FETCH_DOCUMENT, workFetchDocument);
}

function* workSaveDocument({ params, afterSave }) {
  try {
    let endpointMethod = saveDocument;
    if (_.isEmpty(params.article)) {
      endpointMethod = createDocument;
    }
    const begin = new Date();
    const results = yield call(endpointMethod, { bodyItems: params });
    const end = new Date();
    yield put(saveDocumentSuccess(results));
    snackActions.success(i18next.t('ged.save_document_success'));

    // attendre au minimum 1 seconde avant de lancer l'`afterSave` au cas ou il contient un `refresh`.
    // cette contraite est dû au REFRESH_INTERVAL d'elastic
    // {@link https://www.elastic.co/guide/en/elasticsearch/reference/8.8/index-modules.html#index-refresh-interval-setting}
    const elapsed = end.getMilliseconds() - begin.getMilliseconds();
    if (elapsed <= 1100) {
      yield delay(1100 - elapsed);
    }
    yield call(afterSave);
  } catch (response) {
    yield put(saveDocumentError(response));
    console.error(response);
    snackActions.error(i18next.t('ged.save_document_error'));
  }
}

function* watchSaveDocument() {
  yield takeLatest(types.SAVE_DOCUMENT, workSaveDocument);
}

function* workDeleteDocuments({ refresh }) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let documentitemIds;
    if (singleItem) {
      documentitemIds = [singleItem.documentitem];
    } else {
      const checkedItems = yield select((state) => getActiveSelectionItems(state));
      documentitemIds = _.map(checkedItems, 'documentitem');
    }

    const searchState = yield select((state) => state.search);
    const result = yield call(deleteDocumentsFromResults, {
      uriParams: {
        mouvement: getActiveMovementId(searchState),
        documentitem: documentitemIds,
      },
    });
    yield put(deleteDocumentsSuccess());
    if (_.isEmpty(singleItem)) {
      const hasQuickResults = yield select((state) => !_.isEmpty(state.search.quickResults));
      yield put(clearSelection({ quickResultsScope: hasQuickResults }));
    } else {
      yield put(clearSingleItem());
    }
    if (refresh) {
      yield put(refreshResults());
      yield put(cleanupResultsComplete());
    }
    if (result.error === 199) {
      snackActions.warning(result.messageWarning);
    } else {
      snackActions.success(i18next.t('ged.delete_documents_success', { count: documentitemIds.length }));
    }
  } catch (error) {
    yield put(deleteDocumentsError(error));
    console.error(error);
    snackActions.error(i18next.t('ged.delete_documents_error'));
  }
}

function* watchDeleteDocuments() {
  yield takeLatest(types.DELETE_DOCUMENTS, workDeleteDocuments);
}

function* workUpdateStatusDocuments({ status, refresh }) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let documentitemIds;
    if (singleItem) {
      documentitemIds = [singleItem.documentitem];
    } else {
      const checkedItems = yield select((state) => getActiveSelectionItems(state));
      documentitemIds = _.map(checkedItems, 'documentitem');
    }
    const searchState = yield select((state) => state.search);
    const result = yield call(updateStatusFromResults, {
      uriParams: {
        etat: status,
        mouvement: getActiveMovementId(searchState),
        documentitem: documentitemIds,
      },
    });
    yield put(updateStatusDocumentsSuccess());
    if (_.isEmpty(singleItem)) {
      yield put(clearSelection());
    } else {
      yield put(clearSingleItem());
    }
    if (refresh) {
      yield put(refreshResults());
    }
    if (result.error === 199) {
      snackActions.warning(result.messageWarning);
    } else {
      snackActions.success(i18next.t('ged.update_status_documents_success', { count: documentitemIds.length }));
    }
  } catch (error) {
    yield put(updateStatusDocumentsError(error));
    console.error(error);
    snackActions.error(i18next.t('ged.update_status_documents_error'));
  }
}

function* watchUpdateStatusDocuments() {
  yield takeLatest(types.UPDATE_STATUS_DOCUMENTS, workUpdateStatusDocuments);
}

function* workUnlockDocument({ id }) {
  try {
    const uriParams = { };
    if (!_.isEmpty(id)) {
      uriParams.article = id;
    }
    yield call(unlockDocument, { uriParams });
  } catch (response) {
    console.error(response);
  }
}

function* watchUnlockDocument() {
  yield takeEvery(types.UNLOCK_DOCUMENT, workUnlockDocument);
}

export default {
  watchFetchDocument,
  watchSaveDocument,
  watchDeleteDocuments,
  watchUpdateStatusDocuments,
  watchUnlockDocument,
};
