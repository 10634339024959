import { types } from 'generic/core/actions/actions';

const actionsReducer = (
  state = {
    dialogAddToCartOpened: false,
    dialogCreateAlertOpened: false,
    dialogAddToNewsletterOpened: false,
    dialogExportOpened: false,
  },
  action,
) => {
  switch (action.type) {
    case types.OPEN_DIALOG_ADD_TO_CART: {
      return {
        ...state,
        dialogAddToCartOpened: true,
      };
    }
    case types.CLOSE_DIALOG_ADD_TO_CART: {
      return {
        ...state,
        dialogAddToCartOpened: false,
      };
    }
    case types.OPEN_DIALOG_CREATE_ALERT: {
      return {
        ...state,
        dialogCreateAlertOpened: true,
      };
    }
    case types.CLOSE_DIALOG_CREATE_ALERT: {
      return {
        ...state,
        dialogCreateAlertOpened: false,
      };
    }
    case types.OPEN_DIALOG_ADD_TO_NEWSLETTER: {
      return {
        ...state,
        dialogAddToNewsletterOpened: true,
      };
    }
    case types.CLOSE_DIALOG_ADD_TO_NEWSLETTER: {
      return {
        ...state,
        dialogAddToNewsletterOpened: false,
      };
    }
    case types.OPEN_DIALOG_SEND_TO_FRIEND: {
      return {
        ...state,
        dialogSendToFriendOpened: true,
      };
    }
    case types.CLOSE_DIALOG_SEND_TO_FRIEND: {
      return {
        ...state,
        dialogSendToFriendOpened: false,
      };
    }
    case types.OPEN_DIALOG_EXPORT: {
      return {
        ...state,
        dialogExportOpened: true,
      };
    }
    case types.CLOSE_DIALOG_EXPORT: {
      return {
        ...state,
        dialogExportOpened: false,
      };
    }
    case types.OPEN_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS: {
      return {
        ...state,
        dialogUploadFilesToCreateDocumentsOpened: true,
      };
    }
    case types.CLOSE_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS: {
      return {
        ...state,
        dialogUploadFilesToCreateDocumentsOpened: false,
      };
    }
    case types.OPEN_DIALOG_UPLOAD_FILES: {
      return {
        ...state,
        dialogUploadFilesOpened: true,
      };
    }
    case types.CLOSE_DIALOG_UPLOAD_FILES: {
      return {
        ...state,
        dialogUploadFilesOpened: false,
      };
    }
    case types.OPEN_SIDENAV_SUMMARIZE: {
      return {
        ...state,
        sidenavSummarizeOpened: true,
      };
    }
    case types.CLOSE_SIDENAV_SUMMARIZE: {
      return {
        ...state,
        sidenavSummarizeOpened: false,
      };
    }
    default:
      return state;
  }
};

export default actionsReducer;
