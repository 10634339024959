import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { closeDialogEditOrCreateDashboard, saveDashboard } from 'generic/core/dashboard/actions';
import FormDashboard from 'generic/components/forms/FormDashboard';

const SaveDashboardDialogContainer = () => {
  const user = useSelector((state) => state.config.user);
  const activeBaseId = useSelector((state) => state.config.activeBase.base);
  const open = useSelector((state) => state.dashboard.dialogEditOrCreateDashboardOpened);
  const dashboard = useSelector((state) => state.dashboard.editDashboard);
  const loading = useSelector((state) => state.dashboard.saveDashboardLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(closeDialogEditOrCreateDashboard());
  };

  const handleSubmit = (values) => {
    dispatch(saveDashboard(values));
  };

  if (!open) {
    return null;
  }
  let dialogTitle = t('dashboard.add_new_dashboard');
  let initialValues = {
    libelle: '',
    utilisateur: user.utilisateur,
    base: activeBaseId,
  };
  if (!_.isEmpty(dashboard)) {
    dialogTitle = `${t('dashboard.edit_dashboard')} "${dashboard.libelle}"`;
    initialValues = {
      libelle: dashboard.libelle,
      utilisateur: dashboard.utilisateur,
      dashboard: dashboard.dashboard,
      base: activeBaseId,
    };
  }
  return (
    <FormDashboard
      dialogTitle={dialogTitle}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={loading}
      userId={user.utilisateur}
      userIsAdmin={user.admin}
    />
  );
};

export default SaveDashboardDialogContainer;
