import { useSelector } from 'react-redux';

import _ from 'lodash';
import {
  generateLinkToResource, getDocumentBaseId, getValueByBaseFieldsConfig, getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';

export default (document) => {
  const { qesdocument } = document;

  const docBaseId = getDocumentBaseId(document);
  const bases = useSelector((state) => state.config.bases);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const text = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTexte');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieurs.
  const originalText = getValueOrFirstValueFromArray(qesdocument?.PJ_Texte);
  const attachmentXml = getValueOrFirstValueFromArray(qesdocument?.PJ_Fichier_joint);
  const attachmentTranslatedXml = getValueOrFirstValueFromArray(qesdocument?.PJ_Fichier_joint_co);
  const sentences = qesdocument?.Phrase;

  // dernières retouches avant le retour du résulat
  let attachmentTranslated;
  let attachmentTranslatedUrl;
  if (attachmentTranslatedXml) {
    attachmentTranslated = attachmentTranslatedXml.substring(attachmentTranslatedXml.lastIndexOf('/') + 1);
    attachmentTranslatedUrl = generateLinkToResource(docBaseId, attachmentTranslatedXml);
  }

  const attachment = generateLinkToResource(docBaseId, attachmentXml);
  const fileExtension = getValueOrFirstValueFromArray(qesdocument?.PJ_Extension);
  let thumb;
  if (fileExtension === 'png') {
    thumb = generateLinkToResource(docBaseId, attachmentXml);
  }
  const status = +statusXml;
  const snippet = _.truncate(text, {
    length: 700,
    separator: /,? +/,
    omission: '',
  });
  const originalSnippet = _.truncate(originalText, {
    length: 700,
    separator: /,? +/,
  });
  const title = getValueOrFirstValueFromArray(qesdocument?.PJ_Nom_fichier);
  return {
    attachment,
    id,
    thumb,
    snippet,
    status,
    text,
    title,
    sentences,
    fileExtension,
    fileLang: getValueOrFirstValueFromArray(qesdocument?.PJ_Langue),
    fileName: title,
    attachmentTranslated,
    attachmentTranslatedUrl,
    originalText,
    originalSnippet,
  };
};
