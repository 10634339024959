import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UploadFilesToCreateDocuments from 'generic/components/pages/UploadFilesToCreateDocuments';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { addFileToUpload, cleanFilesToUpload, uploadFileToCreateDocument } from 'generic/core/files/actions';

const {
  FILE_UPLOAD_FORMATS,
} = QES_CONSTANTS;

const UploadFilesToCreateDocumentsContainer = ({
  baseId,
  baseMisePageId,
}) => {
  const dispatch = useDispatch();
  const filesToUpload = useSelector((state) => state.files.filesToUpload);

  const uploadParams = {
    base: baseId,
    base_mise_page: baseMisePageId,
    create: true,
  };

  // Cleanup des fichiers lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanFilesToUpload())
  ), [dispatch]);

  const handleFileChange = (target) => {
    if (target.files && target.files.length > 0) {
      dispatch(addFileToUpload([...target.files]));
    }
  };

  const handleUploadClick = () => {
    if (_.isEmpty(filesToUpload)) {
      return;
    }
    _.forEach(filesToUpload, (value) => {
      if (!value.success) {
        const fd = new FormData();
        fd.append('file', value.file);
        dispatch(uploadFileToCreateDocument(uploadParams, fd, value.name));
      }
    });
  };

  return (
    <UploadFilesToCreateDocuments
      acceptExtensions={FILE_UPLOAD_FORMATS.docToUpload.fileExtensions}
      handleFileChange={handleFileChange}
      handleUploadClick={handleUploadClick}
      filesToUpload={filesToUpload}
    />
  );
};

UploadFilesToCreateDocumentsContainer.propTypes = {
  baseId: PropTypes.number.isRequired,
  baseMisePageId: PropTypes.number.isRequired,
};

export default UploadFilesToCreateDocumentsContainer;
